<template>
  <div class="main-content">
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <figure v-if="$store.state.about.img_director_small.length">
                      <img :alt="`${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}`" :src="$store.state.about.img_director_small[0].get_img.media_server_url + $store.state.about.img_director_small[0].get_img.url" class="adaptive" style="height:582px;width:100%;object-fit:contain"/>
                    </figure>
                    <figure v-else>
                      <img :alt="`${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}`" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="height:582px;width:100%;object-fit:cover"/>
                    </figure>
                    <div class="post-main-overlay">
                      <div class="post-main-data">
                        <h2 class="title-21">{{ `${$store.state.about.surnameDirector} ${$store.state.about.nameDirector} ${$store.state.about.patronymicDirector}` }}</h2>
                        <div class="meta-tp-5">
                          <a v-if="$store.state.about.mailDirector" :href="`mailto:${$store.state.about.mailDirector}`" class="date-tp-4"><i class="li_mail"></i>{{ $store.state.about.mailDirector }}</a>
                          <a v-if="$store.state.about.phoneDirector" :href="`tel:${$store.state.about.phoneDirector.replace(/[- )(]/g,'')}`" class="date-tp-4" style="padding-left: 20px"><i class="li_phone"></i>{{ $store.state.about.phoneDirector }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-html="$store.state.about.aboutDirector"></div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadPage',
  async asyncData (context) {
    await context.store.dispatch('headPage');
  },
  metaInfo () {
    return this.$seo(
      `${this.$store.state.about.surnameDirector} ${this.$store.state.about.nameDirector} ${this.$store.state.about.patronymicDirector}`
    );
  }
};
</script>
